import { getSfMedia } from '~/lib/shopify/medias/get-media-by-id'
import { getSfMetaObjects } from '~/lib/shopify/meta-objects/get-meta-objects-by-id'
import {
  NAMESPACES,
  PRODUCT_CARD_METAFIELD_KEYS,
  PRODUCT_METAFIELD_KEYS,
  PRODUCT_SWAVEN_METAFIELD_KEYS,
  TSHOPIFY_ALL_PRODUCT_METAFIELDS_KEYS,
  TSHOPIFY_METAFIELDS_NAMESPACES,
  TSHOPIFY_PRODUCT_CARD_METAFIELDS_KEYS,
  TSHOPIFY_PRODUCT_METAFIELDS_KEYS,
} from '~/lib/shopify/metafields'
import { getSfProduct } from '~/lib/shopify/product/product-by-id'

import { GID, UnlikelyProduct } from '@unlikelystudio/commerce-connector'

import { objectValues } from '~/utils/object-values'

type TserializedProductMetafield = Record<TSHOPIFY_PRODUCT_METAFIELDS_KEYS, any>
type TserializedProductCardMetafield = Record<
  TSHOPIFY_PRODUCT_CARD_METAFIELDS_KEYS,
  any
>

type TserializedAllProductMetafields = Record<
  TSHOPIFY_ALL_PRODUCT_METAFIELDS_KEYS,
  any
>

export type SerializedProductMetafields = Partial<TserializedProductMetafield>
export type SerializedProductCardMetafields =
  Partial<TserializedProductCardMetafield>
export type SerializedAllProductMetafields =
  Partial<TserializedAllProductMetafields>

type metafieldsType = TSHOPIFY_METAFIELDS_NAMESPACES | 'productCard' | 'swaven'

function getMetafieldsKeys(type: metafieldsType) {
  switch (type) {
    case 'product':
      return PRODUCT_METAFIELD_KEYS
    case 'productCard':
      return PRODUCT_CARD_METAFIELD_KEYS
    case 'swaven':
      return PRODUCT_SWAVEN_METAFIELD_KEYS
    default:
      return null
  }
}

export function getShopifyProductMetafieldsList(type: metafieldsType) {
  const metafieldKeys = getMetafieldsKeys(type)

  if (!metafieldKeys) return []

  return Object?.values(metafieldKeys)?.map(
    (key) => `${NAMESPACES.PRODUCT}.${key}`,
  )
}

export function getMetafielKey({
  nameSpace,
  key,
}: {
  nameSpace: TSHOPIFY_METAFIELDS_NAMESPACES
  key: TSHOPIFY_PRODUCT_METAFIELDS_KEYS
}) {
  return `${nameSpace}.${key}`
}

async function formatValue(value: string, type: string, locale: string) {
  switch (type) {
    case 'boolean':
      return JSON?.parse(value)
    case 'metaobject_reference':
      return await getSfMetaObjects({ id: value, locale })
    case 'list.metaobject_reference':
      return await Promise.all(
        JSON?.parse(value)?.map((id: string) =>
          getSfMetaObjects({ id, locale }),
        ),
      )
    case 'product_reference':
      return await getSfProduct({
        id: value as GID,
        locale,
      })
    case 'list.product_reference':
      return await Promise.all(
        JSON?.parse(value)?.map((id: string) =>
          getSfProduct({ id: id as GID, locale }),
        ),
      )
    case 'file_reference':
      return await getSfMedia(value)
    case 'list.file_reference':
      return await Promise.all(
        JSON?.parse(value)?.map((id: string) => getSfMedia(id)),
      )
    case 'rich_text_field':
      return JSON?.parse(value)
    default:
      return value
  }
}

export async function serializeProductMetafields(
  metafields: UnlikelyProduct['metafields'],
  locale: string,
): Promise<Partial<TserializedAllProductMetafields>> {
  const processedMetafields = await Promise.all(
    objectValues(metafields ?? {})?.map(async ({ type, key, value }) => {
      const processedValue = await formatValue(value, type, locale)
      return { [key]: processedValue ?? {} }
    }) ?? [],
  )

  return processedMetafields?.reduce((acc, entry) => {
    return {
      ...(acc ?? {}),
      ...(entry ?? {}),
    }
  }, {})
}
