import { useCallback, useMemo } from 'react'
import { PRISMIC_TYPES } from '~/lib/prismic-types'
import { SHOPIFY_CUSTOM_ATTRIBUTES } from '~/lib/shopify/constants'

import { GID } from '@unlikelystudio/commerce-connector'

import useGetCart from '~/hooks/cart/useGetCart'

export default function useCanAddProductVariant(maxCount: number) {
  const { cart } = useGetCart()

  const variantsIntoCart = useMemo(
    () =>
      cart?.products?.reduce((acc, curr) => {
        if (
          !curr?.variantId ||
          // Custom rules to disable count on sets products
          curr?.attributes?.[SHOPIFY_CUSTOM_ATTRIBUTES.prismicType] ===
            PRISMIC_TYPES.PRODUCT_SETS
        )
          return acc
        if (!acc?.[curr.variantId]) acc[curr.variantId] = curr.quantity
        else acc[curr.variantId] = acc[curr.variantId] + curr.quantity
        return acc
      }, {}) ?? null,
    [JSON.stringify(cart?.products)],
  )

  const canAddProductVariant = useCallback(
    (productVariants: GID[]): boolean => {
      let canAdd = true
      productVariants?.forEach((variant) => {
        if (variantsIntoCart?.[variant] >= maxCount) canAdd &&= false
      })
      return canAdd
    },
    [JSON.stringify(variantsIntoCart)],
  )

  return canAddProductVariant
}
