import {
  UnlikelyImage,
  UnlikelyMedia,
  UnlikelyMediaType,
  UnlikelyProduct,
  UnlikelyVideo,
} from '@unlikelystudio/commerce-connector'

import { VideoProps } from '~/components/Abstracts/Video'

import serializeImage, { Image } from '~/data/serialize-image'

export type Media = { video: VideoProps; image: Image }

function isImageMediaShopify(media: UnlikelyMedia): media is UnlikelyImage {
  return media && media.mediaContentType === UnlikelyMediaType.Image
}

function isVideoMediaShopify(media: UnlikelyMedia): media is UnlikelyVideo {
  return media && media.mediaContentType === UnlikelyMediaType.Video
}

export function serializeMedia(media: UnlikelyMedia): Media {
  if (!media) return null
  return {
    ...(isImageMediaShopify(media) && {
      image: serializeImage(media) ?? null,
    }),
    ...(isVideoMediaShopify(media) && {
      video: {
        poster: media.poster.url,
        sources: media.sources?.map((source) => {
          return {
            src: source.url,
            type: source.format,
            width: source.width,
            height: source.height,
          }
        }),
      },
    }),
  }
}

export default function serializeMediaList(
  mediaList: UnlikelyProduct['media'],
): Media[] {
  return mediaList?.map(serializeMedia)?.filter(Boolean)
}
