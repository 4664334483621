import { getStorePublicCredentials } from '~/lib/shopify/public-client'

import { sfMedia } from '@unlikelystudio/commerce-connector'

export async function getSfMedia(id: string) {
  const credentials = getStorePublicCredentials()

  const media = await sfMedia(
    {
      storeUrl: credentials?.storeUrl,
      storefrontAccessToken: credentials?.storefrontAccessToken,
    },
    { id },
  )

  return media?.media
}
