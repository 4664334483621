import { Nullish } from '~/@types/generic'

export function safeJSONParse<ReturnTypeValue>(
  stringifiedJSON: Nullish<string>,
) {
  try {
    if (!stringifiedJSON) {
      return null
    }

    return JSON.parse(stringifiedJSON) as ReturnTypeValue
  } catch (error) {
    console.error('JSON parsing error: ', error)
    return null
  }
}
