import { SHOPIFY_TYPES } from '~/lib/shopify/constants'
import { METAFIELDS } from '~/lib/shopify/metafields'
import { getSfProduct } from '~/lib/shopify/product/product-by-id'

import { ColorProduct } from '~/components/UI/ColorsProduct'

import { serializeProductMetafields } from '~/data/product-page-data/serialize-product-metafields'
import serializeLink from '~/data/serialize-link'

async function serializeColorVariant(
  product: any,
  locale: string,
  currentId: string,
) {
  const metafields = await serializeProductMetafields(
    product?.metafields,
    locale,
  )

  return {
    link: serializeLink(
      {
        link_type: 'Document',
        type: SHOPIFY_TYPES.PRODUCT_FASHION,
        uid: product?.handle,
      },
      locale,
    ),
    name: metafields?.color_name ?? null,
    color: metafields?.color_hexadecimal ?? null,
    hasBorder: metafields?.color_has_border === true ?? false,
    uid: product?.id ?? null,
    current: product?.id === currentId ?? false,
  }
}

export async function serializeColorVariants(
  metafields: any,
  currentProduct: any,
  locale: string,
): Promise<ColorProduct[]> {
  if (!metafields) return null

  const associatedProducts = metafields?.associated_products ?? null

  // Case where multiple colors has association
  if (associatedProducts) {
    const productsField = associatedProducts?.fields?.find(
      ({ key }) => key === 'products',
    )
    const productsIds = JSON.parse(productsField?.value)

    const colorsQueries = await Promise.all(
      productsIds?.map(async (id) => {
        const product = await getSfProduct({
          id,
          locale,
          metafieldKeys: [
            METAFIELDS.PRODUCT_ASSOCIATED_PRODUCTS.processedKey,
            METAFIELDS.PRODUCT_COLOR_NAME.processedKey,
            METAFIELDS.PRODUCT_COLOR_HAS_BORDER.processedKey,
            METAFIELDS.PRODUCT_COLOR_HEXADECIMAL.processedKey,
          ],
          includePrices: false,
          includeDescriptionHtml: false,
          includeQuantityAvailable: false,
          includeSelectedOptions: false,
          includeSeo: false,
          includeTags: false,
          includeWeight: false,
          variantsFirst: 0,
        })

        return serializeColorVariant(product, locale, currentProduct?.id)
      }),
    )

    const associatedColors = colorsQueries ?? []

    return associatedColors?.filter((color) => color?.name) ?? null
  } else {
    // If there is no association of products
    // But has a color
    if (metafields?.color_name) {
      // Pushing the single color of the product
      const serializedColor = await serializeColorVariant(
        currentProduct,
        locale,
        currentProduct?.id,
      )
      const associatedColors = serializedColor ? [serializedColor] : []

      return associatedColors?.filter((color) => color?.name) ?? null
    }
  }

  return null
}
