import { useRef } from 'react'
import { Variant } from '~/@types/product'
import { PRISMIC_TYPES } from '~/lib/prismic-types'
import { processCartItems } from '~/lib/process-cart-items'
import { SHOPIFY_CHECKOUT_CUSTOM_ATTRIBUTES } from '~/lib/shopify/constants'
import {
  isPageFragranceFromMetafields,
  isPageSetFromMetafields,
} from '~/lib/shopify/metafields/helpers'

import {
  GID,
  UnlikelyCart,
  UnlikelyCartLineUpdateInput,
} from '@unlikelystudio/commerce-connector'
import { useUpdateCartAttributes } from '@unlikelystudio/react-ecommerce-hooks'

import { type ImageProps } from '~/components/UI/Image'

import { useGlobalData } from '~/providers/GlobalDataProvider'

import { useCartActions } from '~/hooks/cart/useCartActions'
import { serializeCartFromPayload } from '~/hooks/cart/useGetCart'
import useAlerts from '~/hooks/useAlerts'
import useLocale from '~/hooks/useLocale'

import { objectAsCustomAttributes } from '~/utils/custom-attributes'
import { safeJSONParse } from '~/utils/safe-json-parse'

const POUCH_L_START = 80

export interface GiftProductProps {
  name: string
  image: ImageProps
  defaultVariant: Variant
  shopifyId: GID
  type: string
}

export type UseSetGiftProductsProps = {
  gifts: GiftProductProps[]
}

export default function useSetGiftProducts() {
  const { global } = useGlobalData() ?? {}
  const locale = useLocale()

  const fragrancePouchs =
    global?.gifts?.types?.find(
      (item) => item?.type === PRISMIC_TYPES.PRODUCT_FRAGRANCE,
    )?.pouchs ?? null

  const triggerAlert = useAlerts()

  const productsToAdd = useRef<GID[] | null>(null)
  const productsToRemove = useRef<UnlikelyCartLineUpdateInput[] | null>(null)

  const { updateCartLinesAction, addLinesToCartAction } = useCartActions({
    onErrorUpdateLines: () => triggerAlert(),
    onErrorAddLines: () => triggerAlert(),
  })

  const { mutateAsync: updateItemsAsync } = updateCartLinesAction
  const { mutateAsync: addItemsAsync } = addLinesToCartAction

  async function handleMutationAdd() {
    const processedProducts = productsToAdd.current
      ?.map((variantId) => {
        // Find the product into the storyblok serialized data
        const gift = fragrancePouchs?.find(
          (fragrancePouch) => fragrancePouch.defaultVariant?.id === variantId,
        )

        // Process checkout attributes
        const checkoutAttributes = processCartItems({
          merchandiseId: gift?.defaultVariant?.id,
          quantity: 1,
          attributes: {
            item_id: gift?.defaultVariant?.sku,
            name: gift?.name,
            image: gift?.image?.src,
            shopifyId: gift?.shopifyId,
            pouch: 'true',
            prismicType: gift?.type,
          },
        })
        return checkoutAttributes
      })
      ?.flat(1)

    await addItemsAsync(processedProducts)
  }

  async function handleMutationRemove() {
    await updateItemsAsync(productsToRemove.current)
  }

  // Update checkout attributes (array of variants gifts ids)
  const { mutate: updateCartCustomAttribute } = useUpdateCartAttributes({
    onSuccess: async () => {
      const hasProductToAdd = (productsToAdd?.current?.length ?? 0) > 0
      const hasProductToRemove = (productsToRemove?.current?.length ?? 0) > 0

      if (hasProductToAdd && hasProductToRemove) {
        await handleMutationAdd()
        await handleMutationRemove()
        productsToAdd.current = null
        productsToRemove.current = null
        return
      }
      if (hasProductToAdd) {
        await handleMutationAdd()
        productsToAdd.current = null
        return
      }
      if (hasProductToRemove) {
        await handleMutationRemove()
        productsToRemove.current = null
        return
      }
    },
  })

  return (unlikelyCart: UnlikelyCart) => {
    const cart = serializeCartFromPayload(unlikelyCart, locale)

    const frangranceProducts =
      cart?.products?.filter(
        (product) =>
          (isPageSetFromMetafields(product) ||
            isPageFragranceFromMetafields(product)) &&
          product?.attributes?._realProduct === 'true',
      ) ?? []

    const { attributes } = cart ?? {}

    // Pouch M all the time that pouch L is out of stock
    // Nina Ricci should get back to us when the pouch L is back in stock.
    const pouchLGift = fragrancePouchs?.find(
      (fragrancePouch) => fragrancePouch?.defaultVariant?.title === 'L',
    )
    const pouchMGift = fragrancePouchs?.find(
      (fragrancePouch) => fragrancePouch?.defaultVariant?.title === 'M',
    )

    // Pouch M all the time that pouch L is out of stock
    // Nina Ricci should get back to us when the pouch L is back in stock.
    // const processedVariantIds =
    //   frangranceProducts?.length === 0
    //     ? []
    //     : cart?.totalAmount > POUCH_L_START
    //     ? [pouchLGift?.defaultVariant?.id]
    //     : [pouchMGift?.defaultVariant?.id]

    const processedVariantIds =
      frangranceProducts?.length === 0 ? [] : [pouchMGift?.defaultVariant?.id]

    const stringifiedVariants = processedVariantIds
      ? JSON.stringify(processedVariantIds)
      : null

    const giftIdsAttributeKey = SHOPIFY_CHECKOUT_CUSTOM_ATTRIBUTES.giftsIds
    const checkoutGiftIds = attributes?.[giftIdsAttributeKey] ?? null

    if (checkoutGiftIds !== stringifiedVariants) {
      // Add gift product to reference
      if (
        productsToAdd.current === null ||
        productsToAdd.current?.length === 0
      ) {
        const checkoutVariantIds = cart?.products?.map(
          (product) => product?.variantId,
        )

        productsToAdd.current =
          processedVariantIds?.filter(
            (variantId) => checkoutVariantIds?.indexOf(variantId) === -1,
          ) ?? null
      }

      // Remove gift product to reference
      if (checkoutGiftIds) {
        const oldProducts = safeJSONParse<GID[]>(checkoutGiftIds) ?? []

        const filteredProducts = oldProducts?.filter(
          (id) => processedVariantIds?.indexOf(id) === -1,
        )
        productsToRemove.current = filteredProducts?.reduce<
          UnlikelyCartLineUpdateInput[]
        >((acc, variantId) => {
          return (acc = [
            ...acc,
            ...(cart?.products?.length > 0
              ? cart.products
                  .filter((item) => item.variantId === variantId)
                  .map((item) => ({ id: item.id, quantity: 0 }))
              : []),
          ])
        }, [])
      }

      // Update custom attributes into checkout
      if (stringifiedVariants) {
        const formattedAttributes = objectAsCustomAttributes(attributes)

        updateCartCustomAttribute({
          cartId: cart.id,
          attributes: [
            ...(formattedAttributes?.length > 0
              ? formattedAttributes.filter(
                  (item) => item.key !== giftIdsAttributeKey,
                )
              : []),
            ...(stringifiedVariants
              ? [
                  {
                    key: giftIdsAttributeKey,
                    value: stringifiedVariants,
                  },
                ]
              : []),
          ],
        })
      }
    }
  }
}
