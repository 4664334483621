import classnames from 'classnames'
import { GlobalTextPreset } from '~/@types/text-preset'
import { GlobalTextStyling } from '~/@types/text-styling'

import { useStyle } from '~/providers/StyleProvider'

import css from './styles.module.scss'

const cx = classnames.bind(css)
type DiscountBadgeProps = {
  className?: string
  value?: string
}

function DiscountBadge({ className, value }: DiscountBadgeProps) {
  const badgeTextStyle = useStyle({
    textPreset: GlobalTextPreset.Label11Haffer,
    textStyling: GlobalTextStyling.UpperCase,
  })

  return value ? (
    <span className={cx(css.DiscountBadge, badgeTextStyle, className)}>
      {value}
    </span>
  ) : null
}

export { DiscountBadge, type DiscountBadgeProps }
