import { Optional } from '~/@types/shared'
import { DEFAULT_LOCALE } from '~/lib/constants'

import { GID, sfProduct } from '@unlikelystudio/commerce-connector'

import {
  getCountryCode,
  getShopifyLanguageCodeFromLocale,
} from '~/utils/locales'
import { isGID } from '~/utils/shopify-ids'

import { getStorePublicCredentials } from '../public-client'

type TsfProduct = { id: GID; locale?: string } & Omit<
  Optional<Parameters<typeof sfProduct>[1], 'variantsFirst'>,
  'id' | 'language'
>

export async function getSfProduct({
  id,
  locale = DEFAULT_LOCALE,
  variantsFirst = 20,
  includePrices = true,
  includeQuantityAvailable = true,
  includeTags = true,
  includeSelectedOptions = true,
  mediaFirst = 10,
  collectionsFirst = 10,
  ...rest
}: TsfProduct) {
  const formatIdIsValid = isGID('Product', id)

  if (formatIdIsValid) {
    const credentials = getStorePublicCredentials()
    const lang = getShopifyLanguageCodeFromLocale(locale)
    const country = getCountryCode(locale)

    const shopifyProducts = await sfProduct(
      {
        storeUrl: credentials?.storeUrl,
        storefrontAccessToken: credentials?.storefrontAccessToken,
      },
      {
        id,
        variantsFirst,
        includePrices,
        includeQuantityAvailable,
        includeTags,
        includeSelectedOptions,
        mediaFirst,
        collectionsFirst,
        metafieldKeys: rest?.metafieldKeys ?? [],
        language: lang,
        country,
        ...rest,
      },
    )

    return shopifyProducts?.product
  } else {
    return null
  }
}
