import { useState } from 'react'
import processEnvSystem from 'unlikely-env/system'
import { SHOPIFY_CHECKOUT_CUSTOM_ATTRIBUTES } from '~/lib/shopify/constants'

import {
  useAddCartLines,
  useRemoveCartLines,
  useUpdateCartAttributes,
  useUpdateCartLines,
} from '@unlikelystudio/react-ecommerce-hooks'
import { useDeviceType } from '@unlikelystudio/react-hooks'

import {
  getCartTypeFromProducts,
  getTrackingDataFromProducts,
  serializeCartFromPayload,
} from '~/hooks/cart/useGetCart'
import { getGAEnvironementName } from '~/hooks/tracking/useGTMTracking'
import useAlerts from '~/hooks/useAlerts'
import useLanguage from '~/hooks/useLanguage'
import useLocale from '~/hooks/useLocale'

type MutationAddOnSuccess = Parameters<
  Parameters<typeof useAddCartLines>[0]['onSuccess']
>[0]
type MutationUpdateOnSuccess = Parameters<
  Parameters<typeof useUpdateCartLines>[0]['onSuccess']
>[0]
type MutationRemoveOnSuccess = Parameters<
  Parameters<typeof useRemoveCartLines>[0]['onSuccess']
>[0]

type MutationAddOnError = Parameters<
  Parameters<typeof useAddCartLines>[0]['onError']
>[0]
type MutationUpdateOnError = Parameters<
  Parameters<typeof useUpdateCartLines>[0]['onError']
>[0]
type MutationRemoveOnError = Parameters<
  Parameters<typeof useRemoveCartLines>[0]['onError']
>[0]

type UseAddToCartActions = {
  onSuccessAddLines?: (data: MutationAddOnSuccess) => void
  onSuccessUpdateLines?: (data: MutationUpdateOnSuccess) => void
  onSuccessRemoveLines?: (data: MutationRemoveOnSuccess) => void
  onErrorAddLines?: (data: MutationAddOnError) => void
  onErrorUpdateLines?: (data: MutationUpdateOnError) => void
  onErrorRemoveLines?: (data: MutationRemoveOnError) => void
}

export function useCartActions({
  onSuccessAddLines,
  onSuccessUpdateLines,
  onSuccessRemoveLines,
  onErrorAddLines,
  onErrorUpdateLines,
  onErrorRemoveLines,
}: UseAddToCartActions) {
  const [isMutatingCart, setIsMutatingCart] = useState(false)

  const lang = useLanguage()
  const locale = useLocale()
  const deviceType = useDeviceType()
  const triggerAlert = useAlerts()

  /* This code snippet is using object destructuring to extract the `mutate` function from the result
  of calling the `useUpdateCartAttributes` hook. The `useUpdateCartAttributes` hook returns an
  object that contains a `mutate` function among other properties. */
  const { mutateAsync: updateCheckoutCustomAttributeAsync } =
    useUpdateCartAttributes({
      onSuccess: () => {
        setIsMutatingCart(false)
      },
      onError: () => triggerAlert(),
    })

  /* The `params` constant is an object that contains various properties related to the current
  environment and settings. Here's a breakdown of what each property represents: */
  const params = {
    id: 'none',
    title: 'none',
    subType: 'none',
    locale: locale ?? 'none',
    country: locale?.split('-')?.[1] ?? locale ?? 'none',
    language: lang ?? 'none',
    deviceType: deviceType ?? 'none',
    environmentName: getGAEnvironementName(),
    codeReleaseVersion:
      processEnvSystem('NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA') ?? 'none',
  }

  /**
   * The function `onActionSuccess` updates custom attributes in a cart based on certain conditions.
   * @param  - It looks like the code snippet you provided is a function named `onActionSuccess` that
   * takes an object as a parameter with a property `cart`. The function performs some operations on
   * the `cart` object and its attributes based on certain conditions.
   */
  async function onActionSuccess({ cart }) {
    const serializedCart = serializeCartFromPayload(cart, locale)
    const cartType = getCartTypeFromProducts(serializedCart?.products)

    // Getting the tracking data from the products
    const checkoutTrackingDataFromProducts = getTrackingDataFromProducts(
      serializedCart?.products,
    )

    if (isMutatingCart) {
      return
    }

    if (
      !serializedCart?.attributes?.[
        SHOPIFY_CHECKOUT_CUSTOM_ATTRIBUTES.productTrackingData
      ] ||
      !serializedCart?.attributes?.[
        SHOPIFY_CHECKOUT_CUSTOM_ATTRIBUTES.checkoutLang
      ] ||
      serializedCart?.attributes?.[
        SHOPIFY_CHECKOUT_CUSTOM_ATTRIBUTES.checkoutLang
      ] !== lang ||
      // If cartType exist and different from the checkout custom attribute change it or if there is no further
      // cartType and it still exist inside checkout custom attribute delete it
      (cartType &&
        serializedCart?.attributes?.[
          SHOPIFY_CHECKOUT_CUSTOM_ATTRIBUTES.orderType
        ] !== cartType) ||
      (!cartType &&
        serializedCart?.attributes?.[
          SHOPIFY_CHECKOUT_CUSTOM_ATTRIBUTES.orderType
        ]) ||
      (checkoutTrackingDataFromProducts &&
        serializedCart?.attributes?.[
          SHOPIFY_CHECKOUT_CUSTOM_ATTRIBUTES.productTrackingData
        ] !== checkoutTrackingDataFromProducts)
    ) {
      setIsMutatingCart(true)

      const processedAttributes =
        Object.entries(serializedCart?.attributes)?.map(([key, value]) => ({
          key,
          value,
        })) ?? []

      const filteredAttributes =
        processedAttributes?.filter(
          (item) => item?.key !== SHOPIFY_CHECKOUT_CUSTOM_ATTRIBUTES.orderType,
        ) ?? []

      const { cart: updatedCart } = await updateCheckoutCustomAttributeAsync({
        cartId: cart?.id,
        attributes: [
          ...filteredAttributes,
          /* Adding a custom attribute checkoutLang to the checkout. */
          {
            key: SHOPIFY_CHECKOUT_CUSTOM_ATTRIBUTES.checkoutLang,
            value: lang,
          },
          /* Adding a custom attribute params to the checkout. */
          {
            key: SHOPIFY_CHECKOUT_CUSTOM_ATTRIBUTES.params,
            value: JSON.stringify(params),
          },
          /* Adding a custom attribute params to the trackingData to the checkout */
          {
            key: SHOPIFY_CHECKOUT_CUSTOM_ATTRIBUTES.productTrackingData,
            value: checkoutTrackingDataFromProducts,
          },
          /* Adding a custom attribute params to the checkout. */
          ...(cartType
            ? [
                {
                  key: SHOPIFY_CHECKOUT_CUSTOM_ATTRIBUTES.orderType,
                  value: cartType,
                },
              ]
            : []),
        ],
      })

      return updatedCart
    }
  }

  /* The `const addLinesToCartAction` is a function that is using the `useAddCartLines` hook. This
  function is responsible for adding lines to the cart. */
  const addLinesToCartAction = useAddCartLines({
    onSuccess: async (data) => {
      const updatedCart = await onActionSuccess(data)

      if (onSuccessAddLines) {
        onSuccessAddLines({ ...data, cart: updatedCart ?? data?.cart })
      }
    },
    onError: (data) => {
      if (onErrorAddLines) {
        onErrorAddLines(data)
      }
    },
  })

  /* The `const removeLinesFromCartAction` is a constant that is assigned the result of calling the
  `useRemoveCartLines` hook. This hook is used to remove lines from the cart. */
  const removeLinesFromCartAction = useRemoveCartLines({
    onSuccess: async (data) => {
      const updatedCart = await onActionSuccess(data)

      if (onSuccessRemoveLines) {
        onSuccessRemoveLines({ ...data, cart: updatedCart ?? data?.cart })
      }
    },
    onError: (data) => {
      if (onErrorRemoveLines) {
        onErrorRemoveLines(data)
      }
    },
  })

  /* The `const updateCartLinesAction` is a constant that is assigned the result of calling the
  `useUpdateCartLines` hook. This hook is used to update the lines in the cart. */
  const updateCartLinesAction = useUpdateCartLines({
    onSuccess: async (data) => {
      const updatedCart = await onActionSuccess(data)

      if (onSuccessUpdateLines) {
        onSuccessUpdateLines({ ...data, cart: updatedCart ?? data?.cart })
      }
    },
    onError: (data) => {
      if (onErrorUpdateLines) {
        onErrorUpdateLines(data)
      }
    },
  })

  return {
    addLinesToCartAction,
    removeLinesFromCartAction,
    updateCartLinesAction,
    isMutatingCart,
    setIsMutatingCart,
  }
}
