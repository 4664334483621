import {
  ShopifyRichTextBlock,
  ShopifyRichTextBlockChildren,
} from '~/components/Abstracts/ShopifyRichText'

export enum ShopifyElements {
  ROOT = 'root',
  PLAIN_TEXT = 'text',
  HEADER_1 = 'header-1',
  HEADER_2 = 'header-2',
  UNORDERED_LIST = 'unordered-list',
  ORDERED_LIST = 'ordered-list',
  LINK = 'link',
  IMAGE = 'image',
  VIDEO = 'video',
  TEXT_FORMATTING = 'formatted-text',
  QUOTE = 'quote',
  TABLE = 'table',
  LINE_BREAK = 'line-break',
  PARAGRAPH = 'paragraph',
}

export const asShopifyRichText = ({
  type = ShopifyElements.PARAGRAPH,
  value,
}: ShopifyRichTextBlockChildren): ShopifyRichTextBlock => ({
  type: ShopifyElements.ROOT,
  children: [{ type, children: [{ type: ShopifyElements.PLAIN_TEXT, value }] }],
})

export const SHOPIFY_SUBTITLE_PARAGRAPH = asShopifyRichText({
  type: ShopifyElements.PARAGRAPH,
  value: 'Short Information',
})

export const SHOPIFY_PIN = asShopifyRichText({
  type: ShopifyElements.PARAGRAPH,
  value: 'New',
})
