import classnames from 'classnames/bind'
import { useEffect, useTransition } from 'react'
import { useTranslate } from 'react-polyglot'
import { ALERT_CODES } from '~/@types/alert'
import { MAX_PRODUCT_SAME_ID } from '~/lib/constants'
import { SHOPIFY_CUSTOM_ATTRIBUTES } from '~/lib/shopify/constants'

import {
  type GID,
  type UnlikelyVariant,
} from '@unlikelystudio/commerce-connector'

import PanelCart from '~/components/Panels/CartPanelWrapped'
import SquaredCta, {
  SquaredCtaColors,
  SquaredCtaProps,
} from '~/components/UI/SquaredCta'

import { usePanel } from '~/providers/PanelProvider'

import { useCartActions } from '~/hooks/cart/useCartActions'
import useSetGiftProducts from '~/hooks/cart/useSetGiftProducts'
import useAlerts from '~/hooks/useAlerts'
import useCanAddProductVariant from '~/hooks/useCanAddProductVariant'
import useGetProductVariantAvailability from '~/hooks/useGetProductVariantAvailability'
import { useHasReachMaxAvailableQuantity } from '~/hooks/useHasReachMaxAvailableQuantity'

import arrayUniqueElement from '~/utils/array-unique-element'

import { CTA } from '~/data/dictionary'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface OnAddToCartButtonCallback {
  isStockAlert: boolean
}

export interface CartItem {
  merchandiseId: string
  quantity: number
  attributes?: {
    key: string
    value: string
  }[]
}

export interface AddToCartButtonProps extends SquaredCtaProps {
  className?: string
  disabled?: boolean
  price?: string
  crossedOutPrice?: string
  cartItems?: CartItem[]
  triggerAddToCart?: boolean
  theme?: SquaredCtaColors
  attributes?: {
    key: string
    value: string
  }[]
  onClick?(): void
  onAdded?(): void
  isOutOfStock?: boolean
  isGiftCard?: boolean
  autoOpenPanel?: boolean
}

function checkIfVariantsAreAvailable(
  variants?: UnlikelyVariant[],
  cartItems?: AddToCartButtonProps['cartItems'],
  isGiftCard?: boolean,
) {
  if (!variants || !cartItems) return []

  const isAvailable = cartItems?.reduce((acc, curr) => {
    const variant =
      variants?.find((variant) => {
        // Get ID from ProductVariant GID
        const processedID = variant?.id ?? null
        const decodedCheckoutId = curr?.merchandiseId ?? null

        return processedID === decodedCheckoutId
      }) ?? null

    if (variant?.quantityAvailable > 0 || isGiftCard) acc.push(curr)
    return acc
  }, [])

  return isAvailable
}
function AddToCartButton({
  className,
  cartItems,
  disabled,
  isOutOfStock,
  onClick,
  onAdded,
  theme,
  price,
  crossedOutPrice,
  triggerAddToCart,
  children,
  autoOpenPanel = true,
  isLoading,
  isGiftCard,
}: AddToCartButtonProps) {
  const [isPending, startTransition] = useTransition()
  const { add: addPanel } = usePanel()
  const triggerAlert = useAlerts()
  const t = useTranslate()

  const canAddProductVariant = useCanAddProductVariant(MAX_PRODUCT_SAME_ID)

  const setGiftProducts = useSetGiftProducts()

  const { addLinesToCartAction } = useCartActions({
    onSuccessAddLines: ({ cart }) => {
      onAdded?.()
      setGiftProducts(cart)
      autoOpenPanel &&
        startTransition(() => {
          addPanel({
            component: <PanelCart />,
          })
        })
    },
    onErrorAddLines: () => {
      triggerAlert()
    },
  })

  const {
    mutate: addItems,
    isLoading: isItemsLoading,
    isError: isItemsError,
  } = addLinesToCartAction

  /* It's getting the GIDs of the products from the cartItems. */
  const GIDS = arrayUniqueElement(
    cartItems
      ?.map((item) => {
        const shopifyId =
          item?.attributes?.find(
            (item) => item.key === SHOPIFY_CUSTOM_ATTRIBUTES?.shopifyId,
          )?.value ?? null
        return shopifyId as GID
      })
      ?.filter(Boolean) ?? [],
  )

  const hasReachMaxAvailableQuantity = useHasReachMaxAvailableQuantity(
    cartItems?.map((item) => item?.merchandiseId),
  )

  const { data: productsAvailabilities, isLoading: isAvailabilityLoading } =
    useGetProductVariantAvailability({
      // Max 15 variants on a product
      variantsFirst: 15,
      ids: GIDS,
    })

  const processedVariants =
    productsAvailabilities
      ?.map(({ product }) => product?.variants)
      ?.flat(2)
      ?.filter(Boolean) ?? null

  const cartItemsAvailable = checkIfVariantsAreAvailable(
    processedVariants,
    cartItems,
    isGiftCard,
  )

  const addToCart = () => {
    if (!isItemsLoading && !isAvailabilityLoading) {
      const processedCartItems =
        cartItemsAvailable
          ?.map((item) => item?.merchandiseId as GID)
          ?.filter(Boolean) ?? []

      if (onClick) onClick()
      const canAdd = canAddProductVariant(processedCartItems)

      if (canAdd && !isAvailabilityLoading) {
        if (cartItemsAvailable?.length > 0 && !hasReachMaxAvailableQuantity) {
          addItems(cartItemsAvailable)
        } else {
          triggerAlert(ALERT_CODES.VARIANT_STOCK_UNAVAILABLE)
        }
      } else {
        triggerAlert(ALERT_CODES.MAX_ITEMS_REACHED_BY_PRODUCT_VARIANT)
      }
    }
  }

  const onAddToCartClick = () => {
    startTransition(() => {
      if (cartItemsAvailable.length === 0 || hasReachMaxAvailableQuantity) {
        return
      }
      addToCart()
    })
  }

  useEffect(() => {
    if (triggerAddToCart) onAddToCartClick()
  }, [triggerAddToCart])

  return (
    <SquaredCta
      className={cx(className, css.addToCart)}
      theme={theme}
      onClick={onAddToCartClick}
      data-testid={'add-to-cart'}
      isLoading={
        isLoading || isPending
          ? isLoading || isPending
          : (isItemsLoading || isAvailabilityLoading) && !isItemsError
      }
      disabled={
        (cartItems?.length > 0 && cartItemsAvailable?.length === 0) ||
        isOutOfStock ||
        hasReachMaxAvailableQuantity ||
        disabled
      }
      withBackground>
      {children ? (
        children
      ) : isOutOfStock || hasReachMaxAvailableQuantity ? (
        t(CTA.OUT_OF_STOCK)
      ) : (
        <div className={css.buttonWrapper}>
          <span className={css.left}>{`${t(CTA.ADD_TO_CART)}`}</span>
          <div className={css.right}>
            <span className={css.price}>{price}</span>
            {crossedOutPrice && (
              <span className={css.crossedOutPrice}>{crossedOutPrice}</span>
            )}
          </div>
        </div>
      )}
    </SquaredCta>
  )
}

AddToCartButton.defaultProps = {
  isOutOfStock: false,
}

export default AddToCartButton
