import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { ALERT_CODES } from '~/@types/alert'
import { getStorePublicCredentials } from '~/lib/shopify/public-client'

import { sfProduct } from '@unlikelystudio/commerce-connector'

import useAlerts from '~/hooks/useAlerts'
import useLocale from '~/hooks/useLocale'

import {
  getCountryCode,
  getShopifyLanguageCodeFromLocale,
} from '~/utils/locales'

type ReturnSFProduct = Awaited<ReturnType<typeof sfProduct>>
type InputProduct = Parameters<typeof sfProduct>[1]
export interface UseGetProductVariantAvailabilityProps {
  ids: InputProduct['id'][]
  variantsFirst: InputProduct['variantsFirst']
  opts?: UseQueryOptions<ReturnSFProduct[]>
}

export default function useGetProductVariantAvailability({
  ids,
  variantsFirst,
  opts,
}: UseGetProductVariantAvailabilityProps) {
  const triggerAlert = useAlerts()
  const locale = useLocale()
  const lang = getShopifyLanguageCodeFromLocale(locale)
  const country = getCountryCode(locale)

  const processed = ['get_product_variant_availability_', ids]

  return useQuery<ReturnSFProduct[]>(
    processed,
    async () => {
      const shop = getStorePublicCredentials()
      const result = await Promise.all(
        ids?.map(async (id) =>
          sfProduct(shop, {
            id,
            variantsFirst,
            includeQuantityAvailable: true,
            language: lang,
            country,
          }),
        ),
      )

      return result
    },
    {
      ...opts,
      onError: (err) => {
        opts?.onError?.(err)
        triggerAlert(ALERT_CODES.SERVER_ERROR)
      },
      refetchOnMount: false,
    },
  )
}
