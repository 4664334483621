type MetaObjectField = {
  key: string
  type?: string
  value?: string
}

export function flatMetaObjectsFieldsToObject(
  fields: MetaObjectField[],
  needsJSONParse = false,
): Record<string, string> {
  return fields?.reduce((acc, field) => {
    const { key, value } = field

    return {
      ...acc,
      [key]: needsJSONParse ? JSON.parse(value) : value,
    }
  }, {})
}
