import { getStorePublicCredentials } from '~/lib/shopify/public-client'

import { sfMetaobject } from '@unlikelystudio/commerce-connector'

import {
  getCountryCode,
  getShopifyLanguageCodeFromLocale,
} from '~/utils/locales'
import { isGID } from '~/utils/shopify-ids'

type TgetSfMetaObjects = { id: string; locale?: string } & Omit<
  Parameters<typeof sfMetaobject>[1],
  'id' | 'language'
>

export async function getSfMetaObjects({
  id,
  locale,
  ...rest
}: TgetSfMetaObjects) {
  const formatIdIsValid = isGID('Metaobject', id)

  if (formatIdIsValid) {
    const credentials = getStorePublicCredentials()
    const lang = getShopifyLanguageCodeFromLocale(locale)
    const country = getCountryCode(locale)

    const store = {
      storeUrl: credentials?.storeUrl,
      storefrontAccessToken: credentials?.storefrontAccessToken,
    }

    const response = await sfMetaobject(store, {
      id,
      language: lang,
      country,
      ...rest,
    })
    const metaobject = response?.metaobject

    // if (!isColorMetaobject) {
    //   return metaobject
    // }

    // const primaryColorMetaobjectIndex = isColorMetaobject
    //   ? metaobject?.fields?.findIndex(
    //       (field) => field?.key === METAOBJECTS_KEYS.PRIMARY_COLOR,
    //     )
    //   : -1

    // if (primaryColorMetaobjectIndex === -1) {
    //   return metaobject
    // }

    // const primaryColorMetaobject =
    //   metaobject?.fields?.[primaryColorMetaobjectIndex]

    // const primaryColorResponse = await sfMetaobject(store, {
    //   id: primaryColorMetaobject.value as GID,
    //   language: lang,
    //   ...rest,
    // })

    // const primaryColor = primaryColorResponse?.metaobject?.fields?.find(
    //   (field) => field?.key === 'name',
    // )

    // if (!primaryColor?.value) {
    //   return metaobject
    // }

    // metaobject.fields[primaryColorMetaobjectIndex] = {
    //   key: METAOBJECTS_KEYS.PRIMARY_COLOR,
    //   type: null,
    //   value: primaryColor.value,
    // }

    return metaobject
  } else {
    return null
  }
}
