import type { Money, Variant, VariantComponent } from '~/@types/product'
import { PRISMIC_TYPES } from '~/lib/prismic-types'

import {
  GID,
  UnlikelyVariant,
  UnlikelyVariantComponent,
} from '@unlikelystudio/commerce-connector'

import getShopifyDiscountPercentage from '~/utils/get-shopify-discount-percentage'

import serializeLink from '~/data/serialize-link'
import serializePrice from '~/data/serialize-price'

export function serializeSetComponents(
  variantComponent: UnlikelyVariantComponent,
): VariantComponent {
  return {
    id: (variantComponent?.id as GID) ?? null,
    title: variantComponent?.title ?? null,
    product: {
      title: variantComponent?.product?.title ?? null,
    },
  }
}

export function getDefaultVariant(variants, type, isProductHeader) {
  // Get default variant from price desc for fragrances
  let defaultVariantOrderByPriceDesc = variants?.reduce((prev, curr) => {
    // Get value from price amount accumulator
    const prevPrice = isNaN(parseInt(prev?.priceAmount))
      ? -1
      : parseInt(prev?.priceAmount)
    // Get value from price amount current variant
    const nextPrice = isNaN(parseInt(curr?.priceAmount))
      ? -1
      : parseInt(curr?.priceAmount)

    // Return the biggest price
    return nextPrice > prevPrice ? curr : prev
  }, null)

  // Get the first variant for fashion
  let defaultFashionVariant = variants?.[0]

  // Setting the first variant available for sale as default when in the product header
  if (isProductHeader) {
    defaultFashionVariant =
      variants?.[0]?.availableForSale === false
        ? variants?.find((variant) => variant?.availableForSale) ??
        variants?.[0]
        : variants?.[0]

    defaultVariantOrderByPriceDesc = defaultVariantOrderByPriceDesc
      ? variants?.find((variant) => variant?.availableForSale) ?? variants?.[0]
      : null
  }

  return (
    (type === PRISMIC_TYPES.PRODUCT_FRAGRANCE
      ? defaultVariantOrderByPriceDesc
      : defaultFashionVariant) ?? null
  )
}

export function serializeVariant(
  node: UnlikelyVariant,
  locale,
  data?,
): Variant {
  if (!node || !locale) return null

  const { price, compareAtPrice } = node

  const link =
    serializeLink({ link_type: 'Document', ...data?.data }, locale) ?? null

  return {
    uid: data?.uid ?? null,
    link,
    id: node?.id ?? null,
    title: node?.title ?? null,
    availableForSale: node?.availableForSale ?? false,
    quantityAvailable: node?.quantityAvailable ?? null,
    barcode: node?.barcode ?? null,
    sku: node?.sku ?? null,
    compareAtPrice: compareAtPrice
      ? serializePrice(
        locale,
        compareAtPrice?.currencyCode,
        compareAtPrice?.amount,
      )
      : null,
    price: serializePrice(locale, price?.currencyCode, price?.amount),
    priceAmount: `${price?.amount}` ?? null,
    compareAtPriceAmount: compareAtPrice ? `${compareAtPrice?.amount}` : null,
    computedDiscountPercentage:
      getShopifyDiscountPercentage(compareAtPrice?.amount, price?.amount) ??
      null,
    unformattedPrice: (price?.amount as unknown as Money) ?? null,
    components: node?.components?.map(serializeSetComponents) ?? [],
    image: node?.image ?? null,
  }
}

export function serializeVariants(items, locale, data?): Variant[] {
  return (
    items?.map((item) => serializeVariant(item, locale, data) ?? null) ?? []
  )
}
