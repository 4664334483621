import type { Variant } from '~/@types/product'
import getCurrency from '~/lib/get-currency'
import { SHOPIFY_TYPES } from '~/lib/shopify/constants'
import { getSfProduct } from '~/lib/shopify/product/product-by-id'

import { UnlikelyImage } from '@unlikelystudio/commerce-connector'

import { Elements } from '~/components/Abstracts/Prismic/types'
import type { ProductCardProps } from '~/components/Slices/ProductCard'

import { flatMetaObjectsFieldsToObject } from '~/utils/flat-meta-objects-fields-to-object'
import {
  getIsProductFashion,
  getIsProductFragrance,
  getIsProductSet,
} from '~/utils/get-shopify-product-type'
import { getPrismicShopifyKey } from '~/utils/locales'

import { serializeColorVariants } from '~/data/product-page-data/serialize-color-variants'
import {
  getShopifyProductMetafieldsList,
  serializeProductMetafields,
} from '~/data/product-page-data/serialize-product-metafields'
import {
  getDefaultVariant,
  serializeVariants
} from '~/data/product-page-data/serialize-variants'
import serializeLink from '~/data/serialize-link'
import { serializeMedia } from '~/data/serialize-media'
import serializePrice from '~/data/serialize-price'
import { asShopifyRichText } from '~/data/shopify/texts'

type PricesAndDiscountFromVariant = {
  price: number
  serializedPrice: string
  compareAtPrice: string | null
  computedDiscountPercentage: string | null
}

function getPricesAndDiscountsFromVariants(
  variants: Variant[],
  locale: string,
): PricesAndDiscountFromVariant {
  const currency = getCurrency(locale)
  const allVariantsPrices =
    variants.map(
      ({ compareAtPrice, computedDiscountPercentage, priceAmount, price }) => {
        return {
          price: parseFloat(priceAmount ?? price),
          serializedPrice: serializePrice(
            locale,
            currency,
            parseFloat(priceAmount ?? price),
          ),
          compareAtPrice: compareAtPrice ?? null,
          computedDiscountPercentage: computedDiscountPercentage ?? null,
        }
      },
    ) ?? []

  // No variants, return null
  if (allVariantsPrices.length === 0) return null
  // Only on variant, return the price
  if (allVariantsPrices.length === 1) return allVariantsPrices[0]

  // Check if all variants have the same price
  const allSamePrice =
    allVariantsPrices.reduce((price, variant) => {
      return variant.price + price
    }, 0) ===
    allVariantsPrices.length * allVariantsPrices[0].price

  // Trying to find the lowest price
  let foundLowestPrice = 999999999
  let foundLowestPriceVariant = allVariantsPrices?.[0]

  allVariantsPrices.forEach((variant) => {
    if (variant.price < foundLowestPrice) {
      foundLowestPrice = variant.price
      foundLowestPriceVariant = variant
    }
  })

  // No prefix needed
  if (allSamePrice) return foundLowestPriceVariant

  // Prefix needed
  return foundLowestPriceVariant ?? null
}

function getLinkFromPageType(
  pageType: (typeof SHOPIFY_TYPES)[keyof typeof SHOPIFY_TYPES],
  product: any,
  locale: string,
) {
  if (
    getIsProductFashion(pageType) ||
    getIsProductFragrance(pageType) ||
    getIsProductSet(pageType)
  ) {
    return serializeLink(
      {
        link_type: 'Document',
        type: pageType,
        uid: product?.handle,
      },
      locale,
    )
  }

  return null
}

export async function serializeCardProductContent(
  sfProduct,
  locale: string,
  options?,
): Promise<ProductCardProps> {
  let colorVariants = null

  // Getting all the metafields
  const metafields = await serializeProductMetafields(
    sfProduct?.metafields,
    locale,
  )

  // Product page type
  const productPageType = metafields?.page_type ?? null

  if (!productPageType) {
    return null
  }

  // Product type
  const productType =
    flatMetaObjectsFieldsToObject(metafields?.product_type?.fields) ?? null

  // Color variants
  if (getIsProductFashion(productPageType)) {
    colorVariants = await serializeColorVariants(metafields, sfProduct, locale)
  }

  // Transform preo key text on rich text
  const productTypeName = productType
    ? {
      label: asShopifyRichText({
        type: Elements.paragraph,
        value: productType?.name,
      }),
    }
    : null

  const sfVariants = sfProduct?.variants ?? null

  // Serialize variants from shopify
  const variants = sfVariants
    ? serializeVariants(sfVariants, locale, {
      uid: sfProduct?.id,
      data: sfProduct,
    }) ?? null
    : []

  const defaultVariant =
    getDefaultVariant(variants, productPageType, false) ?? null

  // Overriding label
  const label =
    getIsProductFragrance(productPageType) && productTypeName !== null
      ? productTypeName
      : getIsProductFashion(productPageType)
        ? { label: null }
        : {}

  const foundPricesAndDiscounts = getPricesAndDiscountsFromVariants(
    variants,
    locale,
  )

  const productLink = getLinkFromPageType(productPageType, sfProduct, locale)

  return {
    name: metafields?.card_name ?? sfProduct?.title ?? null,
    label: metafields?.card_label ?? null,
    image:
      serializeMedia(metafields?.card_image) ??
      serializeMedia(sfProduct?.media?.[0] as UnlikelyImage) ??
      null,
    imageHover: serializeMedia(metafields?.card_image_hover) ?? null,
    link: productLink,
    pin: metafields?.card_pin ?? null,
    // Overload label card only for product fragrance
    ...label,
    colors: colorVariants ?? null,
    intensity: metafields?.intensity ?? null,
    price: foundPricesAndDiscounts?.serializedPrice ?? null,
    compareAtPrice: foundPricesAndDiscounts?.compareAtPrice ?? null,
    computedDiscount:
      foundPricesAndDiscounts?.computedDiscountPercentage ?? null,
    // type: slice?.type ?? null,
    type: productPageType ?? null,
    shopifyId: sfProduct?.id ?? null,
    trackingGTMData: {
      list: options?.list ?? null,
      position: `${options?.index ?? 1}`,
    },
    variants:
      variants?.map(({ title, quantityAvailable }) => ({
        title,
        quantityAvailable,
      })) ?? null,
    quickbuyVariants: variants ?? [],
    defaultVariant,
    productType: productType?.name ?? null,
    hideVariants: metafields?.card_hide_variants ?? null,
  }
}

export default async function serializeCardProduct(
  slice,
  locale,
  opts?,
): Promise<ProductCardProps> {
  if (!slice) return null

  const options = opts ?? {}

  /** Shopify data */
  const shopifyPrismicProductKey = getPrismicShopifyKey() ?? null
  const shopifyData = slice?.data?.[shopifyPrismicProductKey] ?? null

  if (!shopifyData) return null

  const sfProduct = await getSfProduct({
    id: shopifyData?.id,
    locale,
    variantsFirst: 20,
    metafieldKeys: getShopifyProductMetafieldsList('productCard'),
  })

  if (!sfProduct) return null

  const cardContent = await serializeCardProductContent(
    sfProduct,
    locale,
    options,
  )

  return cardContent
}
