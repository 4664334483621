import { useRouter } from 'next/router'

import { CartItem } from '~/components/UI/AddToCartButton'

import useGetCart from '~/hooks/cart/useGetCart'

export function useHasReachMaxAvailableQuantity(
  merchandiseIds: CartItem['merchandiseId'][],
) {
  const {
    cart: { products },
  } = useGetCart()

  const {
    query: { uid },
  } = useRouter()

  let hasReactMaxQuantities = false

  merchandiseIds?.forEach((merchandiseId) => {
    const currentLineItem = products?.find(
      (lineItem) => lineItem?.variantId === merchandiseId,
    )

    if (hasReactMaxQuantities) {
      return
    }

    hasReactMaxQuantities =
      currentLineItem?.quantity === currentLineItem?.quantityAvailable &&
      currentLineItem?.href?.includes(`${uid}`)
  })

  return hasReactMaxQuantities
}
