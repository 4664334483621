import { GID } from '@unlikelystudio/commerce-connector'

const GID_PREFIX = 'gid://shopify'

type Entities = 'Product' | 'ProductVariant' | 'Metaobject' | 'Collection'

export const getReferenceIdFromBase64EncodedId = (
  encodedId: string,
): number => {
  if (!encodedId) return null

  const gid = Buffer.from(encodedId, 'base64').toString()
  const splitGid: Array<string> = gid.split('/')
  const id = splitGid[splitGid.length - 1].split('?')[0]
  return Number(id)
}

type ShopifyEntities = 'Product' | 'ProductVariant' | 'Metaobject'

export function getGIDFromId(id: string | number, property: ShopifyEntities) {
  return `${GID_PREFIX}/${property}/${id}`
}

/**
 * Given a base64 encoded ID, return the GID for the resource
 * @param {string} id - The base64 encoded ID of the resource.
 * @param {string} property - The property of the object you want to reference.
 * @returns A string that can be used to query the GraphQL API.
 */
export function getGIDFromBase64Id(id: string, property: ShopifyEntities) {
  const decodedId = getReferenceIdFromBase64EncodedId(id)
  return getGIDFromId(decodedId, property)
}

/**
 * It takes a string and converts it to a base64 string.
 * @param {string} gid - The group ID of the group you want to get the base64 ID for.
 * @returns A string of the base64 encoded gid.
 */
export function getBase64IDFromAdminGID(gid: string) {
  if (!gid) return null
  const id = Buffer.from(gid, 'utf-8').toString('base64')
  return id
}

export function addGIDPrefix(entity: Entities, id: string | number) {
  return `${GID_PREFIX}/${entity}/${id}` as GID
}

export function removeGIDPrefix(entity: Entities, id: string) {
  return id?.replace(`${GID_PREFIX}/${entity}/`, '')
}

export function isGID(entity: Entities, id: string): id is GID {
  if (typeof id !== 'string') return false

  const trimmedId = removeGIDPrefix(entity, id)
  const gid = addGIDPrefix(entity, trimmedId)

  return gid === id
}
